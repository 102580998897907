import useFetch from 'react-fetch-hook';
import Order from './Order';
import viewModel from './ordersViewModel';
import { useState, useEffect } from 'react';
import { Wrapper } from './ordersStyles';

const API_KEY = 'ijnJu3xVaLK9M6Va99buWYf9GXb6eseEHZfywZRMgUFRaLsV';

function useOrders() {
  const [orders, setOrders] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  let clientId = window.location.pathname.substr(1);

  if (clientId) {
    localStorage.setItem('clientId', clientId);
  } else {
    clientId = localStorage.getItem('clientId');
  }

  const { isLoading, data, error } = useFetch(
    `https://api.store-bali.com/api/delivery/package?clientId=${clientId}`,
    {
      method: 'GET',
      headers: {
        'x-api-key': API_KEY,
        'content-type': 'application/json'
      }
    }
  );

  useEffect(() => {
    if (!isLoading && !isLoaded && !error) {
      setOrders(viewModel.shape(data));
      setLoaded(true);
    }
  }, [isLoading, isLoaded, error, data]);

  return {
    isLoading,
    hasError: Boolean(error),
    orders,
    expandedId,
    setExpandedId
  };
}

function Orders() {
  const { orders, isLoading, hasError, expandedId, setExpandedId } =
    useOrders();
  console.log(orders);

  return (
    <Wrapper>
      {isLoading ? (
        <span className="spinner">Получение данных…</span>
      ) : (
        <ul className="orders">
          {orders.map(order => (
            <Order
              key={order.id}
              order={order}
              isExpanded={expandedId === order.id}
              onExpand={id => setExpandedId(id)}
            />
          ))}
        </ul>
      )}
      {hasError ? 'Не удалось получить данные' : null}
    </Wrapper>
  );
}

export default Orders;
