import Orders from './orders/Orders';
import Contacts from './Contacts';
import GlobalStyle from './GlobalStyle';
import styled from 'styled-components';
import { sizes } from './ui';
import { useState } from 'react';

const Wrapper = styled.div`
  height: 100vh;

  .container {
    max-width: ${sizes.s16};
    margin: 0px auto;
    padding: ${sizes.s5} ${sizes.s3} ${sizes.s10};
    display: flex;
    flex-direction: column;
    height: 100%;

    & > header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 auto;

      padding-bottom: ${sizes.s5};

      h1 {
        margin: 0px;
      }

      .buttons {
        display: flex;
      }
    }
    .content {
      flex: 1 0 auto;
    }
    .text-block {
      flex: 0 0 auto;

      h3 {
        text-transform: uppercase;
        text-align: center;
        margin-top: 30px;
        font-size: ${sizes.s5};
      }
      p {
        font-size: 18px;
        text-align: center;
        margin-bottom: ${sizes.s4};
      }
    }
  }
`;

export default function App() {
  const [isContactsVisible, setContactsVisible] = useState(false);

  const showContacts = () => setContactsVisible(true);
  const hideContacts = () => setContactsVisible(false);

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="container">
        <header>
          {isContactsVisible ? (
            <button className="button secondary" onClick={hideContacts}>
              Заказы
            </button>
          ) : (
            <button className="button secondary" onClick={showContacts}>
              Контакты
            </button>
          )}
        </header>
        <div className="content">
          {isContactsVisible ? <Contacts /> : <Orders />}
        </div>
        <div className="text-block">
          <h3>Стань курьером</h3>
          <p>
            Хотите путешествовать по миру почти бесплатно или окупить часть
            билета - вы можете стать нашим курьером в направлении Бали — Москва
            и Москва — Бали
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
